<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <b-modal
                    ref="with-modal"
                    cancel-variant="outline-secondary"
                    ok-title="واریز شد"
                    cancel-title="لغو"
                    centered
                    :title="'واریز '+relatedCoin+' به کیف پول '+walletName"
                    @ok="submit"
                    v-model="withModal"
            >
                <h5>
                    جهت واریز به کیف پول {{relatedCoin}} سایت خود از QRcode زیر استفاده نمایید
                </h5>
                <b-card
                        img-src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=Example"
                        img-alt="Card image cap"
                        img-top
                        no-body
                        style="max-width:200px"
                        class="mx-auto my-1"
                >
                </b-card>

            </b-modal>

            <b-modal
                    ref="dip-modal"
                    cancel-variant="outline-secondary"
                    ok-title="ارسال "
                    cancel-title="لغو"
                    centered
                    :title="'برداشت '+relatedCoin+' از کیف پول '+walletName"
                    @ok.prevent="submit"
                    v-model="dipModal"
            >
                <b-form>
                    <b-form-group label="مقدار درخواستی">
                        <cleave
                                class="form-control"
                                :raw="false"
                                :options="{numeral: true}"
                                :placeholder="'مقدار '+relatedCoin"
                                v-model="amount"
                        />
                    </b-form-group>
                    <b-form-group label="آدرس کیف پول مقصد">
                        <b-form-input
                                id="address"
                                type="text"
                                placeholder="0Xd54d"
                        />
                    </b-form-group>
                    <b-form-group label="رمز کیف پول">
                        <b-form-input
                                type="password"
                                placeholder="رمز"
                        />
                    </b-form-group>
                </b-form>
            </b-modal>

            <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
                 dir="rtl"
            >
                <h3 v-if="$route.name === 'show-accounts'">
                    لیست زیر مجموعه ها
                </h3>
                <b-button v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    <feather-icon icon="FilterIcon"/>
                    فیلتر
                </b-button>
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        class="text-nowrap"
                        style="min-height : 235px"
                >
                    <template #cell(totalBalance)="data">
                        <span dir="ltr">
                            {{$toLocal(data.item.totalBalance,0) || 0}}
                        </span>
                    </template>

                    <template #cell(updatedAtDateTime)="data">
                        <span dir="ltr">
                            {{$G2J(data.item.updatedAtDateTime)}}
                        </span>
                    </template>
                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(currentPage,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        BForm,
        BFormGroup,
        BFormInput,
        BOverlay,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import Cleave from 'vue-cleave-component'
    import vSelect from 'vue-select';

    export default {
        name: 'Addresses',
        components: {
            SearchAndFilter,
            BCard,
            BButton,
            BTable,
            BForm,
            BFormGroup,
            BFormInput,
            BPagination,
            Cleave,
            vSelect,
            BOverlay
        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 200,
            sortBy: '',
            relatedCoin: '',
            walletName: '',
            wallet: '',
            cryptoUnit: '',
            isSortDirDesc: '',
            amount:'',
            userData: [],
            isActive: false,
            withModal: false,
            dipModal: false,
            feeModal: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            columns: [
                {
                    label: 'نام کاربری',
                    key: 'customer',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'موجودی (تومان)',
                    key: 'totalBalance',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'آخرین تغییر',
                    key: 'updatedAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
            ],
        }),
        watch:{
            '$route.name'(){
                this.getData()
            }
        },
        methods: {
            sort(e) {

                let sort = this.$toSnakeCase(e.sortBy)
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)

            },
            async getData(page, perPage) {
                this.state.loading = true
                const address = '/wallets/customer-toman-wallets'

                let queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query,
                }

                const res = await this.$axios(address, {params: queryParams})

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        mounted() {
            this.getData()
        },
    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
